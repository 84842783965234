import React from "react"
import videoEmbed from "../../../utilities/videoembed"

import "./video_.css" // css

function SmallVideo(props) {
  var link = props.block_content.video
  var video_embed = videoEmbed(link, props.block_content.aspect_ratio)

  var video_height =
    Math.round((100 * 100) / props.block_content.aspect_ratio) / 100

  var video_height_css = video_height + "%"
  var background_color =
    typeof props.block_content.white != "undefined" ? "#FFFFFF" : "#FFFFFF"

  var style = {
    paddingBottom: video_height_css,
    backgroundColor: background_color
  }

  return (
    <div className="video-container">
      <div className="video-content" style={style}>
        <div dangerouslySetInnerHTML={{ __html: video_embed }} />
      </div>
    </div>
  )
}

export default SmallVideo
