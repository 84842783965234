import React, { memo } from "react"
import dimensionsToServe, { whatSizeToServe } from "../utilities/imagesize"

export const ImageDimensionsAndUrl = memo(
  ({ width, height, canvas, url: src, children }) => {
    const dimensions = dimensionsToServe(canvas)
    console.log(canvas, dimensions)

    const url = `//images.weserv.nl/?url=rudinswagerman.nl/${src}&w=${dimensions.width}`
    return children({ ...dimensions, url })
  }
)

const Image = memo(({ width, height, canvas, url }) => {
  return (
    <ImageDimensionsAndUrl
      width={width}
      height={height}
      canvas={canvas}
      url={url}
    >
      {({ url }) => <img src={url} width="100%" height="100%" />}
    </ImageDimensionsAndUrl>
  )
})

export default Image
