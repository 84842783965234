import React, { useRef } from "react"
import useComponentSize from "@rehooks/component-size"

import "./image_.css" // css
import IImage from "../../Image"

function Image(props) {
  const ref = useRef()

  var image_url = props.block_content.image
  console.log({ image_url })

  var image_aspect_ratio = props.block_content.aspect_ratio

  var image_height = Math.round((100 * 100) / image_aspect_ratio) / 100
  var image_height_css = image_height + "%"

  // er moet nog een max-width gekoppeld worden aan de ratio?! liefst zodat een portret-beeld ongeveer hetzelfde oppervlak krijgt?!
  // max-width: vmin
  //
  var max_width_factor = 0.9
  var max_image_width =
    Math.round(100 * 100 * image_aspect_ratio * max_width_factor) / 100
  var max_image_width_css = max_image_width + "vh"

  var container_style = {
    maxWidth: max_image_width_css,
    marginTop: props.block_content.more_space ? 48 : 16,
    marginBottom: props.block_content.more_space ? 52 : 16,
  }

  var style = {
    paddingBottom: image_height_css,
  }

  let canvas = useComponentSize(ref)

  return (
    <div className="image-container" style={container_style}>
      <div
        className="image-content"
        style={style}
        key="image_content"
        ref={ref}
      >
        <IImage
          width={1000}
          height={1000 / image_aspect_ratio}
          canvas={canvas}
          url={image_url}
        />
      </div>
    </div>
  )
}

export default Image
